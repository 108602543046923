/* @import "@fullcalendar/core/main.css";
@import "@fullcalendar/daygrid/main.css"; */
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.admin-home {
  padding: 0px 10px 50px 10px;
}

.admin-heading {
  margin-top: 48px;
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
    text-align: center;
  }

  .fc-header-toolbar .fc-toolbar-chunk {
    display: block;
  }

  #container Button {
    display: block;
    margin-bottom: 2px;
  }

  .admin-heading {
    margin-top: 25px;
  }
}

/* Landing-page css */

.home-body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.home-bg {
  margin-top: 10px;
  font-family: "Raleway", sans-serif;
  color: #282c34;
}

.about-top {
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  color: #282c34;
}

.pages {
  color: blue;
  text-align: center;
  font-size: calc(1.5rem + 2vw);
  margin-top: 10%;
}

.landing-navbar {
  /* background-color: silver; */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.landing-nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  padding: 40px 100px 0px 50px;
  margin-bottom: 10px;
}

.main-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.landing-nav-logo {
  margin-left: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.landing-nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.landing-nav-links {
  color: #282c34;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.landing-nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.landing-nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.landing-nav-item:hover:after {
  width: 100%;
  background: blue;
  color: darkgray;
}

.landing-nav-item .active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.landing-nav-icon {
  display: none;
}

.card-heading {
  font-weight: bold;
}

.logo-img {
  height: 250px;
}

.app-img {
  height: 350px;
}

.store-img {
  height: 50px;
}

.calender-img {
  height: 280px;
}

.about-left-img {
  height: 500px;
  margin-left: 80px;
  margin-top: 40px;
}

.about-img {
  height: 150px;
}

.custom-select {
  height: 35px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  border-radius: 0.25rem;
  padding-left: 8px;
}
.custom-padding-top {
  margin-top: 4px;
}
.padding-top-amount-rc {
  margin-top: 10px;
}

/* Adding visit(timeline) classes start */

.flexJCAC {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-pill {
  padding: 0 !important;
}

.rounded-pill p {
  font-size: 12px !important;
}

.activeProgresNumberCircle,
.inactiveProgresNumberCircle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeProgresNumberCircle p,
.inactiveProgresNumberCircle p {
  background: #556ee6;
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inactiveProgresNumberCircle p {
  background: rgb(180, 180, 180);
}

.stepsForm {
  padding: 0 150px !important;
}
.stepsForm button {
  width: 250px !important;
}

@media (min-width: 1200px) {
  .stepsPillsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .stepsPillsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .stepsForm {
    padding: 0 80px !important;
  }

  .stepsPillsContainer {
    flex-wrap: wrap;
  }

  .stepsPillsContainer > div {
    width: 90vw;
  }
}

@media (max-width: 992px) {
  .accordionsSearchRow div {
    width: 100%;
    margin-bottom: 30px;
  }

  .stepsForm {
    padding: 0 40px !important;
  }
}

@media (max-width: 768px) {
  .stepsForm {
    padding: 0 20px !important;
  }

  .stepsForm button {
    width: 150px !important;
  }

  .invoicesSumText {
    font-size: 14px;
  }

  .activeProgresNumberCircle p,
  .inactiveProgresNumberCircle p {
    height: 30px;
    width: 30px;
    padding: 3px;
    font-size: 12px !important;
  }

  .rounded-pill {
    padding: 0 !important;
  }

  .rounded-pill p {
    font-size: 12px !important;
  }
}
.requiredStar {
  color: rgb(215, 73, 146) !important;
}

/* Adding visit(timeline) classes end */

@media screen and (min-width: 500px) {
  .dose-calculator-values {
    width: 80px;
  }
  .dose-calculator-values-rp {
    align-items: center;
    width: 80px;
    margin-right: 80px;
  }
  .dose-calculator-values-label {
    margin-left: 3px;
  }
  .dose-calculator-values-label-rp {
    margin-left: 3px;
  }
  .dose-calculator-values-label-rp2 {
    margin-left: 3px;
  }
}

@media screen and (max-width: 500px) {
  .dose-calculator-values {
    align-items: center;
    width: 55px;
  }

  .dose-calculator-values-rp {
    align-items: center;
    width: 55px;
    margin-right: 58px;
  }
  .dose-calculator-values-label {
    margin-left: 5px;
  }
  .dose-calculator-values-label-rp {
    margin-left: 8px;
  }
  .dose-calculator-values-label-rp2 {
    margin-right: 30px;
    margin-left: 8px;
  }
  .dose-calculator-values-label-rp3 {
    margin-left: 80px;
  }
}

@media screen and (max-width: 960px) {
  .landing-nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .landing-nav-menu.active {
    background-color: silver;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .landing-nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .landing-nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .landing-nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #282c34;
  }

  .landing-nav-logo {
    align-items: flex-start;
    margin: 0;
  }

  .logo-img {
    height: 150px;
  }

  .app-img {
    height: 250px;
  }

  .store-img {
    height: 50px;
  }

  .calender-img {
    height: 150px;
  }

  .about-left-img {
    height: 350px;
    margin-left: 0;
  }

  .about-img {
    height: 100px;
  }
}
